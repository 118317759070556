import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FeatureLandingComponent } from '@cms/components/feature-landing/feature-landing.component';

var _entryComponents = [
]

var _pipes = [
]

var _directives = [
]

var _Components = [
  ..._pipes,
  ..._entryComponents,
  ..._directives,
    // FeatureLandingComponent
]

var _modules = [

]

@NgModule({
  declarations: [
    ..._Components
  ],
  entryComponents:[
    ..._entryComponents
  ],
  imports: [
    CommonModule,
    ..._modules
  ],
  providers:[

  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    ..._Components
  ]
})
export class SharedSystemModule { }
