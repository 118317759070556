

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KetStringTemplateOutletDirective } from './string_template_outlet.directive';

@NgModule({
  imports: [CommonModule],
  exports: [KetStringTemplateOutletDirective],
  declarations: [KetStringTemplateOutletDirective]
})
export class KetOutletModule {}
