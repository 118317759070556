import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenService } from "./services/authen.service";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root"
})
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, private _authen: AuthenService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    try {
        var user = jwt_decode(this._authen.getToken().access_token);

        console.log(user.user_role);
        if (!next.data.role.find(x =>  x == user.user_role  )){
            throw Error("denied");
        }
        // console.log(next.data.role);
        // console.log(next);

        return true;
    } catch{
      this.router.navigate(['/system']);
      return false
    }

  }
}
